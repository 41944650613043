import { Injectable, NgZone } from '@angular/core'
import Echo from 'laravel-echo'
import { Channel } from 'laravel-echo/src/channel'
import { bindCallback, Observable, of, Subject } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'

import { ApiService } from '~core/services/api.service'
import { UserService } from '~core/services/user.service'
import { truthy } from '~core/utils/operators'
import { environment } from '~env'

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private channel: Channel
  private echo: Echo
  private listeners: {
    [key: string]: Subject<any>
  } = {}
  private privateConnection$: Observable<Channel>

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private ngZone: NgZone,
  ) {
    if (!environment.wsEnabled) {
      return
    }
    this.echo = new Echo({
      broadcaster: 'pusher',
      key: environment.pusherAppKey,
      cluster: environment.pusherAppCluster,
      wsHost: environment.wsHost,
      wsPort: environment.wsPort,
      forceTLS: environment.stage !== 'local',
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            return this.apiService
              .post('/broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .subscribe({
                next: (response) => callback(false, response),
                error: (error) => callback(true, error),
              })
          },
        }
      },
    })
  }

  listen(event: string): Observable<any> {
    if (!this.echo) {
      return of(false).pipe(truthy())
    }
    return this.private().pipe(
      switchMap((connection) => {
        if (!this.listeners[event]) {
          const listener = new Subject<any>()
          connection.listen(event, (event: any) => this.ngZone.run(() => listener.next(event)))
          this.listeners[event] = listener
        }

        return this.listeners[event].asObservable()
      }),
    )
  }

  // notification(userId: string, callback: (payload: unknown) => void): Channel {
  //   return this.private(userId).notification(callback)
  // }

  private private(): Observable<Channel> {
    return this.userService.user.pipe(
      truthy(),
      take(1),
      map((user) => user._id),
      switchMap((userId) => {
        if (!this.privateConnection$) {
          this.privateConnection$ = of(this.echo.private(`App.Models.User.${userId}`))
        }
        return this.privateConnection$
      }),
    )
  }
}
